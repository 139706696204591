import { sendGTMEvent } from "@next/third-parties/google";

type GTMEventProps = {
	event: string;
	ecommerce?: object;
};

/**
 * Send a GTM Enhanced Ecommerce event using `@next/third-parties/google`.
 * @param dataObject - Data object containing event name and optional ecommerce object
 */
export const sendGTMEcommerceEvent = (dataObject: GTMEventProps): void =>
	sendGTMEvent(dataObject);

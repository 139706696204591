import { pruneObject } from "@labdigital/toolkit";
import type { GTMCommerceItemProps } from "../types";
import { itemsMapper } from "./items";

type ViewItemListProps = {
	items: GTMCommerceItemProps[];
	item_list_id?: string;
	item_list_name?: number;
};

export const viewItemList = ({
	items,
	item_list_id,
	item_list_name,
}: ViewItemListProps) => ({
	event: "view_item_list",
	ecommerce: pruneObject({
		item_list_id: item_list_id,
		item_list_name: item_list_name,
		items: itemsMapper(items),
	}),
});

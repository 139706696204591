"use client";

import { graphql } from "@commerce-frontend/types";
import type { ResultOf } from "@graphql-typed-document-node/core";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

// TODO: This is all temporary, we should have better support for fetching line items for context
export const ContextLineItemFragment = graphql(/* GraphQL */ `
	fragment ContextLineItemFragment on LineItem {
		id
		quantity
		productSlug
		productName
		addedAt
		price {
			net {
				...PriceFragment
			}
			gross {
				...PriceFragment
			}
			tax {
				value {
					...PriceFragment
				}
				rate
			}
		}
		variant {
			name
			sku
			primaryImage {
				url
				alt
			}
		}
	}
`);

type AddToCartState = {
	addedLineItem?: ResultOf<typeof ContextLineItemFragment>;
	setAddedLineItem: Dispatch<
		SetStateAction<ResultOf<typeof ContextLineItemFragment> | undefined>
	>;
	errorIsOpen: boolean;
	setErrorOpen: (open: boolean) => void;
};

export const AddToCartContext = createContext<AddToCartState>({
	setAddedLineItem: () => {},
	errorIsOpen: false,
	setErrorOpen: () => {},
});

export const AddToCartProvider = ({
	children,
	initialState,
}: {
	children: React.ReactNode;
	initialState?: {
		addedLineItem?: ResultOf<typeof ContextLineItemFragment>;
		errorIsOpen?: boolean;
	};
}) => {
	const [addedLineItem, setAddedLineItem] = useState(
		initialState?.addedLineItem,
	);
	const [errorIsOpen, setErrorOpen] = useState(
		initialState?.errorIsOpen ?? false,
	);

	return (
		<AddToCartContext.Provider
			value={{
				addedLineItem,
				setAddedLineItem,
				errorIsOpen,
				setErrorOpen,
			}}
		>
			{children}
		</AddToCartContext.Provider>
	);
};

export const useAddToCartContext = () => useContext(AddToCartContext);

import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { forwardRef } from "react";
import { iconMasks } from "../../../icons.generated";
import { cn } from "../../helpers/styles";

export type IconProps = VariantProps<typeof iconVariants> & {
	className?: string;
};

/**
 * Icon component that sets the mask-image CSS property to the corresponding SVG
 * This solution is used to avoid a lot of DOM elements for each SVG icon used.
 *
 * Don't forget to update global.css too.
 */
export const Icon = forwardRef<HTMLSpanElement, IconProps>(
	({ className, icon, size = "md", ...props }, ref) => (
		<span
			ref={ref}
			className={cn(iconVariants({ icon, size }), className)}
			aria-hidden="true"
			{...props}
		/>
	),
);

Icon.displayName = "Icon";

export const iconVariants = cva(
	"g-current inline-block size-6 bg-black stroke-0 text-current [mask-position:center] [mask-repeat:no-repeat] [mask-size:contain]",
	{
		variants: {
			icon: iconMasks,
			size: {
				xs: "size-4",
				sm: "size-5",
				md: "size-6",
			},
		},
	},
);

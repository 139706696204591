/*
This file is generated, please do not edit this file.
The icons in this file are generated from the .svg files inside public/images/icons.

You can regenerate this file by running `pnpm codegen:icons`.
*/
export const iconNames = [
 "add-cart",
 "adjustments",
 "air",
 "arrow-down",
 "arrow-left",
 "arrow-right",
 "arrow-up",
 "arrow-up-circle",
 "automotive",
 "battery",
 "bookmark-alt",
 "box-outline",
 "box-solid",
 "business",
 "calendar",
 "careers",
 "chat-big",
 "chat-history",
 "check",
 "check-circle",
 "chevron-down",
 "chevron-left",
 "chevron-right",
 "chevron-up",
 "clock",
 "construction",
 "cube",
 "customer-service",
 "decrease",
 "diamond",
 "drill",
 "eye",
 "eye-off",
 "filter",
 "flag",
 "garden",
 "gift",
 "glue",
 "heart-outline",
 "heart-solid",
 "home-line",
 "increase",
 "info",
 "invoices",
 "lock-closed",
 "login",
 "logout",
 "mail",
 "mail-big",
 "menu",
 "menu-alt-4",
 "metal",
 "minus",
 "minus-circle",
 "phone-big",
 "play",
 "plus",
 "plus-circle",
 "power",
 "question-mark",
 "receipt",
 "reply",
 "reset",
 "returns",
 "returns-big",
 "review-star",
 "sandpaper",
 "saw",
 "search",
 "search-sm",
 "select",
 "shopping-bag",
 "shopping-cart",
 "smiley-1",
 "smiley-2",
 "smiley-3",
 "smiley-4",
 "smiley-5",
 "store-outline",
 "tag",
 "thumb-down",
 "thumb-up",
 "time-circle",
 "toolcart",
 "tools",
 "trash",
 "truck",
 "user",
 "user-add",
 "user-logged-in",
 "warning",
 "warning-circle",
 "weight",
 "x",
 "x-circle",
] as const;

export type IconName = typeof iconNames[number];

export const iconMasks = {
	"add-cart": "icon-add-cart",
	"adjustments": "icon-adjustments",
	"air": "icon-air",
	"arrow-down": "icon-arrow-down",
	"arrow-left": "icon-arrow-left",
	"arrow-right": "icon-arrow-right",
	"arrow-up": "icon-arrow-up",
	"arrow-up-circle": "icon-arrow-up-circle",
	"automotive": "icon-automotive",
	"battery": "icon-battery",
	"bookmark-alt": "icon-bookmark-alt",
	"box-outline": "icon-box-outline",
	"box-solid": "icon-box-solid",
	"business": "icon-business",
	"calendar": "icon-calendar",
	"careers": "icon-careers",
	"chat-big": "icon-chat-big",
	"chat-history": "icon-chat-history",
	"check": "icon-check",
	"check-circle": "icon-check-circle",
	"chevron-down": "icon-chevron-down",
	"chevron-left": "icon-chevron-left",
	"chevron-right": "icon-chevron-right",
	"chevron-up": "icon-chevron-up",
	"clock": "icon-clock",
	"construction": "icon-construction",
	"cube": "icon-cube",
	"customer-service": "icon-customer-service",
	"decrease": "icon-decrease",
	"diamond": "icon-diamond",
	"drill": "icon-drill",
	"eye": "icon-eye",
	"eye-off": "icon-eye-off",
	"filter": "icon-filter",
	"flag": "icon-flag",
	"garden": "icon-garden",
	"gift": "icon-gift",
	"glue": "icon-glue",
	"heart-outline": "icon-heart-outline",
	"heart-solid": "icon-heart-solid",
	"home-line": "icon-home-line",
	"increase": "icon-increase",
	"info": "icon-info",
	"invoices": "icon-invoices",
	"lock-closed": "icon-lock-closed",
	"login": "icon-login",
	"logout": "icon-logout",
	"mail": "icon-mail",
	"mail-big": "icon-mail-big",
	"menu": "icon-menu",
	"menu-alt-4": "icon-menu-alt-4",
	"metal": "icon-metal",
	"minus": "icon-minus",
	"minus-circle": "icon-minus-circle",
	"phone-big": "icon-phone-big",
	"play": "icon-play",
	"plus": "icon-plus",
	"plus-circle": "icon-plus-circle",
	"power": "icon-power",
	"question-mark": "icon-question-mark",
	"receipt": "icon-receipt",
	"reply": "icon-reply",
	"reset": "icon-reset",
	"returns": "icon-returns",
	"returns-big": "icon-returns-big",
	"review-star": "icon-review-star",
	"sandpaper": "icon-sandpaper",
	"saw": "icon-saw",
	"search": "icon-search",
	"search-sm": "icon-search-sm",
	"select": "icon-select",
	"shopping-bag": "icon-shopping-bag",
	"shopping-cart": "icon-shopping-cart",
	"smiley-1": "icon-smiley-1",
	"smiley-2": "icon-smiley-2",
	"smiley-3": "icon-smiley-3",
	"smiley-4": "icon-smiley-4",
	"smiley-5": "icon-smiley-5",
	"store-outline": "icon-store-outline",
	"tag": "icon-tag",
	"thumb-down": "icon-thumb-down",
	"thumb-up": "icon-thumb-up",
	"time-circle": "icon-time-circle",
	"toolcart": "icon-toolcart",
	"tools": "icon-tools",
	"trash": "icon-trash",
	"truck": "icon-truck",
	"user": "icon-user",
	"user-add": "icon-user-add",
	"user-logged-in": "icon-user-logged-in",
	"warning": "icon-warning",
	"warning-circle": "icon-warning-circle",
	"weight": "icon-weight",
	"x": "icon-x",
	"x-circle": "icon-x-circle",
};

"use client";

import {
	sendGTMEcommerceEvent,
	viewItemList,
} from "@commerce-frontend/gtm-ecommerce";
import type { GTMCommerceItemProps } from "@commerce-frontend/gtm-ecommerce/types";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo, useState } from "react";

type ViewItemListType = {
	setProductInView: (item: GTMCommerceItemProps) => void;
};

const ViewItemList = createContext<ViewItemListType | undefined>(undefined);

/**
 * Hook that allows you to set a product as in view so it gets sent to Google Analytics.
 */
export const useViewItemList = (): ViewItemListType => {
	const context = useContext(ViewItemList);
	if (!context) {
		if (process.env.NODE_ENV === "development")
			console.warn(
				"useViewItemList is being ran without being wrapped by <ViewItemListProvider />",
			);
		return {
			setProductInView: () => {
				if (process.env.NODE_ENV === "development") {
					console.warn(
						"setProductInView is being ran without being wrapped by <ViewItemListProvider />",
					);
				}
			},
		};
	}
	return context;
};

/**
 * Provider that tracks when products are in view and sends the event to Google Analytics.
 */
export const ViewItemListProvider = ({ children }: PropsWithChildren) => {
	const [productsInView, setProductInView] = useState<GTMCommerceItemProps[]>(
		[],
	);

	useMemo(() => {
		if (productsInView.length > 0) {
			sendGTMEcommerceEvent(
				viewItemList({
					items: productsInView,
				}),
			);
		}
	}, [productsInView]);

	const setInView = (item: GTMCommerceItemProps) => {
		setProductInView((prev) => [...prev, item]);
	};

	return (
		<ViewItemList.Provider value={{ setProductInView: setInView }}>
			{children}
		</ViewItemList.Provider>
	);
};

import { pruneObject } from "@labdigital/toolkit";
import type { GTMCommerceItemProps } from "../types";

export const itemsMapper = (items?: GTMCommerceItemProps[]) =>
	items?.map((data, index) =>
		pruneObject({
			item_id: data.item_id,
			item_name: data.item_name,
			affiliation: data.affiliation,
			coupon: data.coupon,
			discount: data.discount,
			index: index,
			item_brand: data.item_brand,
			item_category: data.item_category,
			item_category2: data.item_category2,
			item_category3: data.item_category3,
			item_category4: data.item_category4,
			item_category5: data.item_category5,
			item_list_id: data.item_list_id,
			item_list_name: data.item_list_name,
			item_variant: data.item_variant,
			location_id: data.location_id,
			price: data.price ? data.price : undefined,
			quantity: data.quantity,
		}),
	);

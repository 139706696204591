import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { typography } from "../../base/Typography/typography";
import { cn } from "../../helpers/styles";

export const ProductCard = forwardRef<
	ElementRef<"article">,
	ComponentPropsWithRef<"article">
>(({ children, className, ...props }, ref) => {
	return (
		<article
			ref={ref}
			className={cn(
				"relative flex items-start gap-4 text-inherit md:flex-col",
				className,
			)}
			{...props}
		>
			{children}
		</article>
	);
});

ProductCard.displayName = "ProductCard";

export const ProductCardImage = forwardRef<
	ElementRef<"div">,
	ComponentPropsWithRef<"div">
>(({ children, className, ...props }, ref) => {
	return (
		<div
			ref={ref}
			className={cn(
				"relative flex w-full flex-horizontal items-center justify-center rounded-xs object-contain shadow-1 hover:shadow-2 motion-safe:transition-shadow",
				className,
			)}
			{...props}
		>
			{children}
		</div>
	);
});

ProductCardImage.displayName = "ProductCardImage";

export const ProductCardInfo = forwardRef<
	ElementRef<"div">,
	ComponentPropsWithRef<"div">
>(({ children, className, ...props }, ref) => {
	return (
		<div
			ref={ref}
			className={cn("flex w-3/5 shrink-0 flex-col gap-2 md:w-full", className)}
			{...props}
		>
			{children}
		</div>
	);
});

ProductCardInfo.displayName = "ProductCardInfo";

export const ProductCardInfoCta = forwardRef<
	ElementRef<"div">,
	ComponentPropsWithRef<"div">
>(({ children, className, ...props }, ref) => {
	return (
		<div
			ref={ref}
			className={cn(
				"flex flex-col gap-3 lg:grid lg:grid-cols-product-card-cta lg:grid-rows-product-card-cta lg:gap-1",
				className,
			)}
			{...props}
		>
			{children}
		</div>
	);
});

ProductCardInfoCta.displayName = "ProductCardInfoCta";

export const ProductCardInfoProductName = forwardRef<
	ElementRef<"h3">,
	ComponentPropsWithRef<"h3">
>(({ children, className, ...props }, ref) => {
	return (
		<h3
			ref={ref}
			className={cn(
				typography("p"),
				"line-clamp-1 font-normal text-sm md:text-base",
				className,
			)}
			{...props}
		>
			{children}
		</h3>
	);
});

ProductCardInfoProductName.displayName = "ProductCardInfoProductName";

export const ProductCardInfoProductDescription = forwardRef<
	ElementRef<"p">,
	ComponentPropsWithRef<"p">
>(({ children, className, ...props }, ref) => {
	return (
		<p
			ref={ref}
			className={cn(
				"line-clamp-2 pb-2 font-normal text-secondary text-sm",
				className,
			)}
			{...props}
		>
			{children}
		</p>
	);
});

ProductCardInfoProductDescription.displayName =
	"ProductCardInfoProductDescription";

export const ProductCardInfoPrice = forwardRef<
	ElementRef<"p">,
	ComponentPropsWithRef<"p">
>(({ children, className, ...props }, ref) => {
	return (
		<p
			ref={ref}
			className={cn("flex font-bold text-base md:text-xl", className)}
			{...props}
		>
			{children}
		</p>
	);
});

ProductCardInfoPrice.displayName = "ProductCardInfoPrice";

export const ProductCardInfoStock = forwardRef<
	ElementRef<"div">,
	ComponentPropsWithRef<"div">
>(({ children, className, ...props }, ref) => {
	return (
		<div
			ref={ref}
			className={cn(
				"inline-flex items-center gap-1 font-medium text-xs",
				className,
			)}
			{...props}
		>
			{children}
		</div>
	);
});

ProductCardInfoStock.displayName = "ProductCardInfoStock";

export const ProductCardInfoButtons = forwardRef<
	ElementRef<"div">,
	ComponentPropsWithRef<"div">
>(({ children, className, ...props }, ref) => {
	return (
		<div
			ref={ref}
			className={cn("flex gap-2 lg:row-span-2 lg:items-center", className)}
			{...props}
		>
			{children}
		</div>
	);
});

ProductCardInfoButtons.displayName = "ProductCardInfoButtons";

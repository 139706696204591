"use client";

import type { ImageProps } from "next/image";
import NextImage from "next/image";
import type { ElementRef, ReactEventHandler } from "react";
import { forwardRef, useCallback } from "react";

const srcPlaceholder = "/images/placeholder.jpg";

/**
 * Custom version of the Next.js Image component, can be updated to use an image loader or do other custom logic
 */
export const Image = forwardRef<
	ElementRef<typeof NextImage>,
	Omit<ImageProps, "loader">
>(({ ...props }, ref) => {
	// Show fallback image when original image cannot be shown
	const handleError: ReactEventHandler<HTMLImageElement> = useCallback(
		(e) => {
			if (srcPlaceholder) {
				const { src = "" } = e.currentTarget;
				e.currentTarget.previousSibling?.remove();
				e.currentTarget.setAttribute("originalsrc", src);
				e.currentTarget.setAttribute("srcset", "");
				e.currentTarget.setAttribute("src", srcPlaceholder);
			}
		},
		[srcPlaceholder],
	);

	return <NextImage {...props} onError={handleError} ref={ref} />;
});

Image.displayName = "Image";
